import React, { useContext, useEffect, useState } from "react";
import axios from "../../axios";
import moment from "moment/moment";
import ReactPaginate from "react-paginate";
import Loader from "../../utils/Loader";
import { FormContext } from "../../pages/FormTable";
import { useDispatch, useSelector } from "react-redux";
import { asyncCountryData } from "../../actions/rootAction";
import ContactUsModal from "./ContactUsModal";
import { useNavigate } from "react-router-dom";
const ContactUsTable = ({ searchInp, isUpdate, rowsLimit }) => {
  const DUMMY_DATA = [
    {
      name: "Test User",
      email: "test@example.com",
      phone: "+92 1234567890",
    },
    {
      name: "Test User 2",
      email: "test2@example.com",
      phone: "+92 1234567890",
    },
    {
      name: "Test User 3",
      email: "test3@example.com",
      phone: "+92 1234567890",
    },
  ];

  const [contactUsData, setContactUsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [countryList, setCountryList] = useState({})

  const dispatch = useDispatch()

  const countryData = useSelector((state) => state.rootData.countryData)


  const { filterOptions } = useContext(FormContext);

  useEffect(() => {
    setIsLoading(true);
    const url = "/contactus";
    let paramObj = {
      page: currentPage,
    };
    if (searchInp !== "") {
      paramObj.searchTerm = searchInp.trim();
    }

    if (rowsLimit) {
      paramObj.limit = rowsLimit;
    } else {
      paramObj.limit = 10;
    }

    paramObj = { ...paramObj, ...filterOptions };

    const config = {
      params: paramObj,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    axios
      .get(url, config)
      .then((res) => {
        const { data, totalPages } = res.data;
        setContactUsData(data);
        setTotalPages(totalPages);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err.response);
        setIsLoading(false);
      });
  }, [currentPage, isUpdate]);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [isUpdate])


  useEffect(() => {
    dispatch(asyncCountryData())
  }, [])


  useEffect(() => {
    let countryObj = {}
    for (let i = 0; i < countryData.length; i++) {
      countryObj[countryData[i].label] = countryData[i].value
    }
    setCountryList(countryObj)

  }, [countryData])



  return (
    <>
      <div className="module_student_table table-responsive scrollbar-template">
        {isLoading ? (
          <Loader Height={"40vh"} />
        ) : (
          <table className="table-template-style ">
            <thead>
              <tr>
                <th scope="col" className="table-first-element">
                  Sr no.
                </th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone no.</th>
                <th scope="col">Subject</th>
                <th scope="col">Country</th>
                <th scope="col">Message</th>
                <th scope="col">Created At</th>
                <th scope="col">Actions</th>

              </tr>
            </thead>
            <tbody>
              {contactUsData.map((data, i) => {
                return (
                  <TableItem
                    data={data}
                    index={i}
                    currentPage={currentPage}
                    rowsLimit={rowsLimit}
                    countryList={countryList}
                  />
                );
              })}
            </tbody>
          </table>
        )}

        {/* <h4 className="text-center text-black mt-4">No Students Found!</h4> */}
      </div>
      {!isLoading && (
        <div className="react_paginate_Div">
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={7}
            pageCount={totalPages}
            previousLabel="< previous"
            activeClassName={"active"}
            renderOnZeroPageCount={null}
            forcePage={currentPage !== 1 ? currentPage - 1 : ""}
          />
        </div>
      )}
    </>
  );
};

export default ContactUsTable;

const TableItem = ({ index, data, currentPage, rowsLimit, countryList }) => {
  const [isShow, setIsShow] = useState(false)
  const navigate = useNavigate()


  return (
    <tr key={index}>
      <td>{(currentPage - 1) * rowsLimit + index + 1}</td>
      <td>{data.name}</td>
      <td>{data.email}</td>
      <td>{(countryList[data.country] || " ") + " " + data.phone}</td>
      <td>{data.subject}</td>
      <td>{data.country}</td>
      <td >
        <button className="contact-us-button" onClick={() => setIsShow(true)}>view</button>
      </td>
      <td>{moment(data.createdAt).format("DD MMM YYYY LT")}</td>
      <td>
        <button className="contact-us-button m-0" onClick={()=>navigate(`/forms/contactus-email/${data.email}`)}>view mails</button>
      </td>
      {isShow && <ContactUsModal modalShow={isShow} setModalShow={setIsShow} message={data.message} name={data.name} />}
    </tr>
  );
};
