import Header from "../components/layout/Header";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import "../asset/css/newDashboard.css";
import DashboardLeaderBoard from "../components/Dashboard/DashboardLeaderBoard";
import GraphContent from "../components/Dashboard/GraphContent";
import { useState } from "react";


export default function Dashboard() {

  const [activeTabber, setActiveTabber] = useState("Course_Analysis")

  return (
    <>
      <LayoutWrapper>
        <Header />
        <div className="dashboard-parent">
          <DashboardLeaderBoard />
          <div className="dashboard-graph-tabber-container">
            <div className="dashboard-graph-tabber-heading">
              <p style={{ cursor: "pointer" }} className={activeTabber === "Course_Analysis" && "active"} onClick={() => { setActiveTabber("Course_Analysis") }} >Course Analysis</p>
              <p style={{ cursor: "pointer" }} className={activeTabber === "Leads" && "active"} onClick={() => { setActiveTabber("Leads") }} >Leads</p>
              <p style={{ cursor: "pointer" }} className={activeTabber === "Targets" && "active"} onClick={() => { setActiveTabber("Targets") }} >Targets</p>
            </div>
            <GraphContent activeTabber={activeTabber} />
          </div>
        </div>
      </LayoutWrapper>
    </>
  )
}