import React from "react";
import LoginImg from "../components/login/LoginImg";
import LoginForm from "../components/login/LoginForm";

const Login = () => {
  return (
    <div className="login_container">
      {/* <div className="row">
        <div className="col-xl-7 col-lg-7 display-sm-none">
          <LoginImg />
        </div>
        <div className="col-xl-5 col-lg-5 col-12">
          <LoginForm />
        </div>
      </div> */}

        <LoginForm />

    </div>
  );
};

export default Login;
