import { useCallback, useEffect, useMemo, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { GoAlertFill } from "react-icons/go";
import axios from "../../axios"
import { ColorRing } from "react-loader-spinner";
import { AnimatePresence, motion } from "framer-motion";
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { IoMdRefresh } from "react-icons/io";
import { MdClearAll } from "react-icons/md";
import { RiChatDeleteLine } from "react-icons/ri";
import { FiDelete } from "react-icons/fi"
import "../../asset/css/milestones.css";
import { useSelector } from "react-redux";

export default function MilestonesManager() {

	const user = useSelector((state) => state.profileData.userData);
  const [salesPersons, setSalesPersons] = useState([]);
  const [filterButtonOption, setFilterButtonOption] = useState("CURR");
  const [currentSalesPerson, setCurrentSalesPerson] = useState(`${user._id}__${user.username}`);
  const [leadsData, setLeadsData] = useState(null);
  const [graphData, setGraphData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [noMilestonesFlag, setNoMilestonesFlag] = useState(false);
  const [triggerAchieved, setTriggerAchieved] = useState(false);
  const [milestoneToEdit, setMilestoneToEdit] = useState(null);

  // Setting milestones
  const [showMileStoneSetter, setShowMileStoneSetter] = useState(false);
  const [milestones, setMilestones] = useState(null);

  async function fetchSalesPersons() {
    try {
      const url = "/dashboard-data/sales-team";
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`
        }
      }
      const response = await axios.get(url, config);
      if (response.status === 200) {
        setSalesPersons(response.data?.salesTeam);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchMilestoneCourseData() {
    try {
      console.log("Getting milestone achieved data")
      const url = "/dashboard-data/get-milestones-achieved";
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`
        }
      }
      const data = {
        milestones
      }
      const response = await axios.post(url, data, config);

      if(response.status === 200) {
        setTriggerAchieved(false);
        console.log("Milestone achieved response:", response)
        if(Array.isArray(response.data?.data)) {
          setMilestones(prev => ({
            ...prev,
            courses: prev.courses.map(milestoneCourse => {
              // Find matching response course
              const responseCourse = response.data.data.find(
                rc => rc.courseName === milestoneCourse.name
              );
              
              // Return new object with updated achieved value if found
              return responseCourse 
                ? { ...milestoneCourse, achieved: responseCourse.achieved }
                : milestoneCourse;
            })
          }));
        }
      }
    } catch (error) {
      setTriggerAchieved(false);
      console.log(error);
    }
  }

  useEffect(()=>{
    if(milestones && Array.isArray(milestones?.courses) && triggerAchieved) {
      fetchMilestoneCourseData();
    }
  },[triggerAchieved])

  useEffect(() => {
    fetchSalesPersons();
  }, [salesPersons.length]);


  async function fetchLeadsData(controller) {
    try {
      setLoading(true);
      const url = "/dashboard-data/get-milstone-data";
      const config = {
        signal: controller.signal,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`
        }
      };
      const response = await axios.post(url, {
        salesPerson: currentSalesPerson?.split("__")[0],
      }, config);
      if (response.status === 200) {
        setLoading(false);
        if (response.data?.success === false) {
          setNoMilestonesFlag(true);
          setMilestones(null);
        } else {
          setMilestones(response.data?.milestones);
          setTriggerAchieved(true);
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(error)
    }
  }

  useEffect(() => {
    setNoMilestonesFlag(false);
    if(showMileStoneSetter === true) return;
    if (!currentSalesPerson) return;
    const controller = new AbortController();
    fetchLeadsData(controller);
    return () => {
      controller.abort();
    }
  }, [filterButtonOption, currentSalesPerson, showMileStoneSetter])

  console.log(milestones, )

  return (
    <>
      <div className="graph-inner-heading">
        <div className="graph-inner-heading-left">
          <p className="graph-title-main">{currentSalesPerson?.split("__")[1] ? `Milestones manager for ${currentSalesPerson?.split("__")[1]}` : 'Please choose a team member'} {currentSalesPerson?.split("__")[1] && <button className="overview-refresh-btn"><IoMdRefresh /> Refresh</button> }</p>
        </div>

        <div className="graph-inner-heading-right">
        
        </div>
      </div>

      <AnimatePresence mode="wait">
          <motion.div initial={{opacity:0, x: 5}} animate={{opacity:1, x: 0}} exit={{opacity: 0, x: -5}} key="dashboard-graph-tabber-container" className="dashboard-graph-tabber-content">
            <div className="graph-inner">
              {
                (!milestones && noMilestonesFlag) && (
                  <>
                    <h5 className="no-data-found-heading"><GoAlertFill style={{ color: "#ffa665", fontSize: "22px", marginBottom: "5px" }} /> No Data Available</h5>
                    <p className="no-data-found-content">Please ask the admin to allot a lead target for you</p>
                  </>
                )
              }
              {
                !currentSalesPerson && !loading && (
                  <>
                    <h5 className="no-data-found-heading"><GoAlertFill style={{ color: "#ffa665", fontSize: "22px", marginBottom: "5px" }} /> Please Choose a team member</h5>
                    <p className="no-data-found-content">Tip: You can choose a team member from the select option above</p>
                  </>
                )
              }
              {loading && <ColorRing width={80} height={80} colors={['#528ff0', '#528ff0', '#528ff0', '#528ff0', '#528ff0']} />}
              {(milestones && !loading) && (
                <div className="milestones-holder">
                  {milestones?.courses?.map(item => (
                    <div className="milestone-trackcard" onClick={()=>{ setMilestoneToEdit(item); setShowMileStoneSetter(true)}}>
                      <div className="cicprogr-hldr">
                        <CircularProgressbar 
                          value={Math.round((item.achieved / item.target)*100)} 
                          text={`${Math.round((item.achieved / item.target)*100)}%`} 
                          strokeWidth={18}
                          styles={buildStyles({
                            // Rotation of path and trail, in number of turns (0-1)
                        
                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                            strokeLinecap: 'butt',
                            // Text size
                            textSize: '18 px',
                        
                            // How long animation takes to go from one percentage to another, in seconds
                            pathTransitionDuration: 0.5,
                        
                            // Can specify path transition in more detail, or remove it entirely
                            // pathTransition: 'none',
                        
                            // Colors
                            pathColor: `rgb(15, 158, 25)`,
                            textColor: '#000',
                            trailColor: '#d6d6d6',
                            backgroundColor: '#3e98c7',
                          })}
                        />
                      </div>
                      {/* <p className="milestone-bdg-delete">
                      <FiDelete style={{transform:"rotate(180deg)"}} />
                      </p> */}
                      <p className={`milestone-badge ${new Date() > new Date(item.deadline) ? "milestone-bdg-xpired" : "milestone-bdg-live"}`}>{
                        new Date() > new Date(item.deadline) ? "Expired" : "Live"
                      }
                      </p>
                      <div>
                        <p className="milestone-title" >{item.name}</p>
                        <p className="milestone-title-subhead" > <span>{item.achieved} / {item.target}</span> leads achieved so far</p>
                        <p className="milestone-title-subhead" >Deadline: {new Date(item.deadline).toLocaleDateString()}</p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </motion.div>
      </AnimatePresence>
    </>
  )
}