import React, { useContext, useEffect, useState } from "react";
import axios from "../../axios";
import moment from "moment/moment";
import ReactPaginate from "react-paginate";
import Loader from "../../utils/Loader";
import { FormContext } from "../../pages/FormTable";
import { CgArrowLongLeft, CgArrowLongRight } from "react-icons/cg";
import { asyncCountryData } from "../../actions/rootAction";
import { useDispatch, useSelector } from "react-redux";
const EnquiryTable = ({ searchInp, isUpdate, rowsLimit }) => {
  const DUMMY_DATA = [
    {
      name: "Test User",
      email: "test@example.com",
      phone: "+92 1234567890",
    },
    {
      name: "Test User 2",
      email: "test2@example.com",
      phone: "+92 1234567890",
    },
    {
      name: "Test User 3",
      email: "test3@example.com",
      phone: "+92 1234567890",
    },
  ];

  const [enquiryData, setEnquiryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch()
  const [countryList , setCountryList] = useState({})

  const countryData = useSelector((state) => state.rootData.countryData)


  const { filterOptions } = useContext(FormContext);



  useEffect(() => {
    setIsLoading(true);
    const url = `/course/all-query`;

    let paramObj = {
      page: currentPage,
    };

    paramObj = { ...paramObj, ...filterOptions };

    if (searchInp !== "") {
      paramObj.searchTerm = searchInp.trim();
    }

    if (rowsLimit) {
      paramObj.limit = rowsLimit;
    } else {
      paramObj.limit = 10;
    }

    const config = {
      params: paramObj,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };
    axios
      .get(url, config)
      .then((res) => {
        const { data, totalPages, totalCount } = res.data;
        setTotalPages(totalPages);
        setEnquiryData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err.response);
      });
  }, [currentPage, isUpdate]);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [isUpdate]);

  useEffect(() => {
    dispatch(asyncCountryData())
  }, [])


  useEffect(() => {
    let countryObj = {}
    for (let i = 0; i < countryData.length; i++) {
      countryObj[countryData[i].label] = countryData[i].value
    }
    setCountryList(countryObj)
    
  }, [countryData])



  return (
    <>
      <div className="module_student_table table-responsive scrollbar-template">
        {isLoading ? (
          <Loader Height={"40vh"} />
        ) : (
          <table className="table-template-style ">
            <thead>
              <tr>
                <th scope="col" className="table-first-element">
                  Sr no.
                </th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone no.</th>
                <th scope="col">Inform for</th>
                <th scope="col">Created At</th>
                <th scope="col">Program</th>
              </tr>
            </thead>
            <tbody>
              {enquiryData.map((data, i) => {
                return (
                  <TableItem
                    data={data}
                    index={i}
                    currentPage={currentPage}
                    rowsLimit={rowsLimit}
                    countryList={countryList}
                  />
                );
              })}
            </tbody>
          </table>
        )}

        {/* <h4 className="text-center text-black mt-4">No Students Found!</h4> */}
      </div>
      {!isLoading && (
        <div className="react_paginate_Div">
          <ReactPaginate
            breakLabel="..."
            // nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={totalPages}
            // previousLabel="< previous"
            activeClassName={"active"}
            renderOnZeroPageCount={null}
            forcePage={currentPage !== 1 ? currentPage - 1 : ""}
            previousLabel={
              <span className="prev-button">
                <CgArrowLongLeft />
                Previous
              </span>
            }
            nextLabel={
              <span className="next-button">
                Next
                <CgArrowLongRight />
              </span>
            }
          />
        </div>
      )}
    </>
  );
};

export default EnquiryTable;

const TableItem = ({ index, data, currentPage, rowsLimit , countryList }) => {
  return (
    <tr key={index}>
      <td>{(currentPage - 1) * rowsLimit + index + 1}</td>
      <td>{data.username}</td>
      <td>{data.email}</td>
      <td>{(countryList[data.country] || " " )+ " " + data.phone_no}</td>
      <td>{data.inquiry_for}</td>
      <td>{moment(data.createdAt).format("DD MMM YYYY LT")}</td>
      <td>{data.course}</td>
    </tr>
  );
};
