import Header from "../components/layout/Header";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import "../asset/css/calc.css";
import { useState } from "react";
import OneTimePayment from "../components/Calculator/OneTimePayment";
import InstallmentPayment from "../components/Calculator/InstallmentPayment";

export default function Calculator() {
  const [tabber, setTabber] = useState("Installment_Payment");

  return (
    <>
    <LayoutWrapper>
      <Header />
      <div className="calc-parent">

        <div className="calc-card">
          <div className="upper-tabber-tray">
            <div onClick={()=>{setTabber("Installment_Payment")}} className={`${tabber === "Installment_Payment" && "calc-tabber-active"} tabber-calc-item`}>Installments</div>
            <div onClick={()=>{setTabber("One_Time_Payment")}} className={`${tabber === "One_Time_Payment" && "calc-tabber-active"} tabber-calc-item`}>One Time Payment</div>
          </div>
          <div className="calc-content">
            {
              tabber === "One_Time_Payment" ? (
                <OneTimePayment />
              ) : tabber === "Installment_Payment" ? (
                <InstallmentPayment />
              ) : (<></>)
            }
          </div>
        </div>
      </div>
    </LayoutWrapper>
  </>
  )
}