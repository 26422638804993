import { useEffect } from "react";
import { GoAlertFill } from "react-icons/go";
import CourseAnalysis from "./CourseAnalysis";
import LeadsAnalysis from "./LeadsAnalysis";
import TargetAnalysis from "./TargetAnalysis";

export default function GraphContent({activeTabber}) {

	useEffect(()=>{
		
	},[activeTabber])

	return (
		<>
		{activeTabber === "Course_Analysis" && <CourseAnalysis />}
		{activeTabber === "Leads" && <LeadsAnalysis />}
		{activeTabber === "Targets" && <TargetAnalysis />}
		</>
	)
}