import React, { useEffect, useState } from "react";
import { BiEditAlt } from "react-icons/bi";
import { useParams } from "react-router-dom";
import axios from "../../axios";
import moment from "moment";
import { FaCircleCheck } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { images, notifyDanger, notifySuccess } from "../../actions/customFn";
import { Accordion, AccordionItem } from "react-bootstrap";
import { CiEdit } from "react-icons/ci";
import SmallLoader from "../../utils/SmallLoader";
import { FaRegEdit } from "react-icons/fa";

const FormStudentInfo = ({ formData }) => {
  const [remarkTxt, setRemarkTxt] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [amount, setAmount] = useState("350");
  const [invoiceData, setInvoiceData] = useState({});
  const [amountPaid, setAmountPaid] = useState(0);
  const [dueAmount, setDueAmount] = useState(0);

  const [isInstallmentIIEdit, setIsInstallmentIIEdit] = useState(false);
  const [isInstallmentIIIEdit, setIsInstallmentIIIEdit] = useState(false);

  const [installmentTwoCustomAmount, setInstallmentTwoCustomAmount] =
    useState(0);

  const [installmentThreeCustomAmount, setInstallmentThreeCustomAmount] =
    useState({
      one: 0,
      two: 0,
    });

  const [isCheckOpenOne, setIsCheckOpenOne] = useState(false);
  const [isCheckOpenTwo, setIsCheckOpenTwo] = useState(false);

  const [isCustomPriceLoading, setIsCustomPriceLoading] = useState(false);

  const [isUpdate, setIsUpdate] = useState(false);

  const [isInvoiceLoading, setIsInvoiceLoading] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [updatedAmount, setUpdatedAmount] = useState(0);

  const submitData = (e) => {
    e.preventDefault();
    const url = "leads/update-lead-remark";
    const data = {
      email: formData.email,
      remark: remarkTxt,
    };

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    axios
      .put(url, data, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        setRemarkTxt("");
      })
      .catch((err) => {
        notifyDanger(err?.response?.data?.message || "Some Error Occured");
        console.log(err);
      });
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleInputChange = (e) => {
    setAmount(e.target.value);
  };

  useEffect(() => {
    const url = "/user/get-my-invoice";
    const params = {
      email: formData.email,
      program_name: formData.program_name,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
      params,
    };

    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        setInvoiceData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [formData, isUpdate]);

  useEffect(() => {
    if (invoiceData && Object.keys(invoiceData).length > 0) {
      let discountAmount;
      if (invoiceData.installment) {
        discountAmount =
          invoiceData.installment_data.discount_amount ||
          Number(invoiceData.amount_paid);
        let dueAmount =
          invoiceData?.remaining_installment?.discount_amount || 0;
        dueAmount = invoiceData.isGst
          ? Number(dueAmount) + Math.round(0.18 * dueAmount)
          : Number(dueAmount);
        setDueAmount(dueAmount);
      } else {
        discountAmount =
          Number(invoiceData.amount_paid) -
          Math.round(
            invoiceData.amount_paid * (invoiceData.discount_percent / 100)
          );
        setDueAmount(0);
      }
      let amountToPaid = invoiceData.isGst
        ? Number(discountAmount) + Math.round(0.18 * discountAmount)
        : Number(discountAmount);
      setAmountPaid(amountToPaid);
    }
  }, [invoiceData]);


  useEffect(()=>{
    setUpdatedAmount(amountPaid);
  },[amountPaid])

  useEffect(() => {
    if (invoiceData && invoiceData.installment_type === "installment-two") {
      setInstallmentTwoCustomAmount(
        invoiceData.customInstallmentAmountII
          ? invoiceData.customInstallmentAmountII
          : dueAmount
      );
    }

    if (invoiceData && invoiceData.installment_type === "installment-three") {
      setInstallmentThreeCustomAmount({
        one: invoiceData.customInstallmentAmountII || Math.round(dueAmount / 2),
        two:
          invoiceData.customInstallmentAmountIII || Math.round(dueAmount / 2),
      });
    }
  }, [invoiceData, dueAmount]);

  const updateCustomAmount = () => {
    setIsCustomPriceLoading(true);
    const url = "/user/update-invoice-custom-amount";

    const data = {
      id: invoiceData._id,
      customInstallmentAmountII: installmentThreeCustomAmount.one,
      customInstallmentAmountIII: installmentThreeCustomAmount.two,
    };

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    axios
      .put(url, data, config)
      .then((res) => {
        const { message } = res.data;
        setIsCustomPriceLoading(false);
        setIsUpdate((p) => !p);
        setIsCheckOpenOne(false);
        setIsCheckOpenTwo(false);
        notifySuccess(message);
      })
      .catch((err) => {
        setIsCustomPriceLoading(false);
        setIsCheckOpenOne(false);
        setIsCheckOpenTwo(false);
      });
  };

  const sendInvoice = (obj) => {
    setIsInvoiceLoading(true);
    const url = "/user/send-installment-invoice";
    const data = {
      email: invoiceData.email,
      name: invoiceData.username,
      address: invoiceData.address,
      contact: invoiceData.contact_no,
      invoice_id: invoiceData.invoice_id,
      due_amount: obj.dueAmount,
      amount_paid: obj.amount,
      currency_symbol: invoiceData.currency_symbol,
      program_name: invoiceData.program_name,
      discount_percent: 0,
      isGst: false,
      remainingInstallment: {
        amount: obj.dueAmount,
      },
      id: invoiceData._id,
      afterPayment: true,
    };

    if (obj.isInstallmentII) {
      data.isInstallmentII = obj.isInstallmentII;
    }

    if (obj.isInstallmentIII) {
      data.isInstallmentIII = obj.isInstallmentIII;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    axios
      .post(url, data, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        setIsInvoiceLoading(false);
      })
      .catch((err) => {
        setIsInvoiceLoading(false);

        notifyDanger(err?.response?.data?.message || "Some error occured!");
      });
  };

  const sendUpdatedInvoice = () => {
    setIsInvoiceLoading(true);
    const url = "/user/send-updated-invoice";
    const data = {
      email: invoiceData.email,
      name: invoiceData.username,
      address: invoiceData.address,
      contact: invoiceData.contact_no,
      invoice_id: invoiceData.invoice_id,
      due_amount: invoiceData.due_amount,
      amount_paid: updatedAmount,
      currency_symbol: invoiceData.currency_symbol,
      program_name: invoiceData.program_name,
      discount_percent: 0,
      isGst: invoiceData.isGst,
      remainingInstallment: {
        amount: invoiceData.due_amount,
      },
      id: invoiceData._id,
      afterPayment: true,
    };

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
    };

    axios
      .post(url, data, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        setIsInvoiceLoading(false);
        setIsUpdate((p) => !p);
        setIsEdit(false)

      })
      .catch((err) => {
        setIsInvoiceLoading(false);

        notifyDanger(err?.response?.data?.message || "Some error occured!");
      });
  };

  return (
    <div className="formstudent_info_container">
      <div className="row">
        <div className="col-6">
          <div className="formstudent_info_left">
            <div className="formstudent_info_heading">
              <h2>Personal Information</h2>
            </div>
            <div className="formstudent_info_details">
              <div className="formstudent_info_detail_item">
                <h3>Intern Code</h3>
                <h4>{formData.intern_code}</h4>
              </div>
              <div className="formstudent_info_detail_item">
                <h3>Enroll At</h3>
                <h4>{moment(formData.createdAt).format("DD MMM YYYY LT")}</h4>
              </div>
              <div className="formstudent_info_detail_item">
                <h3>Email</h3>
                <h4>{formData.email}</h4>
              </div>
              <div className="formstudent_info_detail_item">
                <h3>Phone</h3>
                <h4>{formData.contact_no}</h4>
              </div>
              <div className="formstudent_info_detail_item">
                <h3>Address</h3>
                <h4>{formData.address}</h4>
              </div>
              <div className="formstudent_info_detail_item">
                <h3>Country</h3>
                <h4>{formData.country}</h4>
              </div>
              <div className="formstudent_info_detail_item">
                <h3>Program</h3>
                <h4>{formData.program_name}</h4>
              </div>
              <div className="formstudent_info_detail_item">
                <h3>Batch</h3>
                <h4>{formData.batch_name}</h4>
              </div>
            </div>
          </div>

          <div className="formstudent_info_bottom">
            <div className="formstudent_info_bottom_heading">
              <h2> Remarks</h2>
              <BiEditAlt />
            </div>
            <form onSubmit={submitData}>
              <div className="formstudent_info_bottom_div">
                <textarea
                  placeholder="Enter Remark"
                  rows={"3"}
                  required
                  value={remarkTxt}
                  onChange={(e) => setRemarkTxt(e.target.value)}
                />
                <div className="d-flex mt-2 justify-content-end">
                  <button>
                    <FaCircleCheck className="formstudent_info_bottom_svg" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        {(localStorage.getItem("oeson_role") === "SalesOperation" ||
          localStorage.getItem("oeson_role") === "SalesManager") && (
            <>
              {invoiceData &&
                Object.keys(invoiceData).length > 0 &&
                invoiceData?.installment_type ? (
                <div className="col-6">
                  <div className="formstudent_info_right">
                    <div className="formstudent_info_right_header">
                      <h2>Payment History</h2>
                      <h4>
                        Payment Plan -{" "}
                        {invoiceData?.installment_type
                          ? invoiceData.installment_type === "installment-three"
                            ? "Pay in Parts (||| Parts)"
                            : invoiceData.installment_type === "installment-two"
                              ? "Pay in Parts (|| Parts)"
                              : "One Time Payment"
                          : ""}
                      </h4>
                    </div>

                    <Accordion>
                      <AccordionItem>
                        <Accordion.Header>
                          <div className="formstudent_info_right_acc_header">
                            <span>
                              <h4>
                                {invoiceData.installment
                                  ? "Installment |"
                                  : "One Time"}{" "}
                              </h4>
                              <p>
                                Paid on{" "}
                                {moment(invoiceData.createdAt).format(
                                  "DD MM YYYY"
                                )}
                              </p>
                            </span>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="d-flex justify-content-between align-items-start">

                            <div className="formstudent_info_right_acc_body">
                              {isEdit ? (
                                <input
                                  type="number"
                                  value={updatedAmount}
                                  onChange={(e) => setUpdatedAmount(e.target.value)}
                                />
                              ) : (
                                <h3>
                                  {" "}
                                  {invoiceData.currency_symbol} {amountPaid}
                                  /-
                                </h3>
                              )}

                              <p>
                                Thank you for choosing {invoiceData.program_name}{" "}
                                wishing you all the best for a successful future.
                              </p>
                            </div>
                            <div className="formstudent_info_right_acc_body_svg">
                              <FaRegEdit
                                fill="gray"
                                onClick={() => setIsEdit((p) => !p)}
                              />
                            </div>
                          </div>
                          {isEdit && (
                            <div className="formstudent_info_right_acc_body_submit">
                              <button
                                onClick={sendUpdatedInvoice}
                                disabled={isInvoiceLoading}
                              >
                                {isInvoiceLoading ? (
                                  <SmallLoader color={"#fff"} />
                                ) : (
                                  "Send Invoice"
                                )}
                              </button>
                            </div>

                          )}
                        </Accordion.Body>
                      </AccordionItem>
                    </Accordion>

                    {invoiceData.installment_type === "installment-two" && (
                      <Accordion>
                        <AccordionItem>
                          <Accordion.Header>
                            <div className="formstudent_info_right_acc_header">
                              <span>
                                <h4>Installment ||</h4>
                                <p>
                                  {invoiceData.isInstallmentII
                                    ? `Paid On ${moment(
                                      invoiceData.installmentIIPaidOn
                                    ).format("DD MM YYYY")}`
                                    : `Due On ${moment(invoiceData.createdAt)
                                      .add(1, "months")
                                      .format("DD MM YYYY")}`}
                                </p>
                              </span>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="formstudent_info_right_acc_body">
                              {isInstallmentIIEdit ? (
                                <input
                                  type="text"
                                  value={installmentTwoCustomAmount}
                                  onChange={(e) =>
                                    setInstallmentTwoCustomAmount(e.target.value)
                                  }
                                />
                              ) : (
                                <h3>
                                  {invoiceData.currency_symbol}{" "}
                                  {installmentTwoCustomAmount}/-
                                </h3>
                              )}
                              <p>
                                Thank you for choosing {invoiceData.program_name}{" "}
                                wishing you all the best for a successful future.
                                <CiEdit
                                  size={30}
                                  onClick={() =>
                                    setIsInstallmentIIEdit((p) => !p)
                                  }
                                />
                              </p>
                              <div className="formstudent_info_right_acc_body_check">
                                <input
                                  type="checkbox"
                                  checked={isCheckOpenOne}
                                  onChange={(p) => setIsCheckOpenOne(!p)}
                                />
                                <p>Paid</p>
                              </div>
                              {isCheckOpenOne && (
                                <button
                                  className="formstudent_info_right_acc_body_send"
                                  onClick={() => {
                                    const obj = {
                                      amount: installmentTwoCustomAmount,
                                      dueAmount: 0,
                                      isInstallmentII: true,
                                    };
                                    sendInvoice(obj);
                                  }}
                                >
                                  {isInvoiceLoading ? (
                                    <SmallLoader color={"#fff"} />
                                  ) : (
                                    "send invoice"
                                  )}
                                </button>
                              )}
                            </div>
                          </Accordion.Body>
                        </AccordionItem>
                      </Accordion>
                    )}

                    {invoiceData.installment_type === "installment-three" && (
                      <>
                        <Accordion>
                          <AccordionItem>
                            <Accordion.Header>
                              <div className="formstudent_info_right_acc_header">
                                <span>
                                  <h4>Installment ||</h4>
                                  <p>
                                    {invoiceData.isInstallmentII
                                      ? `Paid On ${moment(
                                        invoiceData.installmentIIPaidOn
                                      ).format("DD MM YYYY")}`
                                      : `Due On ${moment(invoiceData.createdAt)
                                        .add(1, "months")
                                        .format("DD MM YYYY")}`}
                                  </p>
                                </span>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="formstudent_info_right_acc_body">
                                {isInstallmentIIIEdit ? (
                                  <input
                                    type="number"
                                    value={installmentThreeCustomAmount.one}
                                    onChange={(e) =>
                                      setInstallmentThreeCustomAmount({
                                        ...installmentThreeCustomAmount,
                                        one: e.target.value,
                                      })
                                    }
                                  />
                                ) : (
                                  <h3>
                                    {invoiceData.currency_symbol}{" "}
                                    {installmentThreeCustomAmount.one} /-
                                  </h3>
                                )}
                                <p>
                                  Thank you for choosing{" "}
                                  {invoiceData.program_name} wishing you all the
                                  best for a successful future.
                                  <CiEdit
                                    size={30}
                                    onClick={() =>
                                      setIsInstallmentIIIEdit((p) => !p)
                                    }
                                  />
                                </p>
                                <div className="formstudent_info_right_acc_body_check">
                                  <input
                                    type="checkbox"
                                    checked={isCheckOpenOne}
                                    onChange={() => {
                                      setIsCheckOpenOne((p) => !p);
                                    }}
                                  />
                                  <p>Paid</p>
                                </div>
                                {isCheckOpenOne && (
                                  <button
                                    className="formstudent_info_right_acc_body_send"
                                    onClick={() => {
                                      const obj = {
                                        amount: installmentThreeCustomAmount.one,
                                        dueAmount:
                                          installmentThreeCustomAmount.two,
                                        isInstallmentII: true,
                                      };
                                      sendInvoice(obj);
                                    }}
                                  >
                                    {isInvoiceLoading ? (
                                      <SmallLoader color={"#fff"} />
                                    ) : (
                                      "send invoice"
                                    )}
                                  </button>
                                )}
                              </div>
                            </Accordion.Body>
                          </AccordionItem>
                        </Accordion>
                        <Accordion>
                          <AccordionItem>
                            <Accordion.Header>
                              <div className="formstudent_info_right_acc_header">
                                <span>
                                  <h4>Installment |||</h4>
                                  <p>
                                    {invoiceData.isInstallmentII
                                      ? `Paid On ${moment(
                                        invoiceData.installmentIIPaidOn
                                      ).format("DD MM YYYY")}`
                                      : `Due On ${moment(invoiceData.createdAt)
                                        .add(2, "months")
                                        .format("DD MM YYYY")}`}
                                  </p>
                                </span>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="formstudent_info_right_acc_body">
                                {isInstallmentIIIEdit ? (
                                  <input
                                    type="number"
                                    value={installmentThreeCustomAmount.two}
                                    onChange={(e) =>
                                      setInstallmentThreeCustomAmount({
                                        ...installmentThreeCustomAmount,
                                        two: e.target.value,
                                      })
                                    }
                                  />
                                ) : (
                                  <h3>
                                    {invoiceData.currency_symbol}{" "}
                                    {installmentThreeCustomAmount.two}/-
                                  </h3>
                                )}
                                <p>
                                  Thank you for choosing{" "}
                                  {invoiceData.program_name} wishing you all the
                                  best for a successful future.
                                </p>
                                <div className="formstudent_info_right_acc_body_check">
                                  <input
                                    type="checkbox"
                                    checked={isCheckOpenTwo}
                                    onChange={() => {
                                      setIsCheckOpenTwo((p) => !p);
                                    }}
                                  />
                                  <p>Paid</p>
                                </div>
                                {isCheckOpenTwo && (
                                  <button
                                    className="formstudent_info_right_acc_body_send"
                                    onClick={() => {
                                      const obj = {
                                        amount: installmentThreeCustomAmount.two,
                                        dueAmount: 0,
                                        isInstallmentIII: true,
                                      };
                                      sendInvoice(obj);
                                    }}
                                  >
                                    {isInvoiceLoading ? (
                                      <SmallLoader color={"#fff"} />
                                    ) : (
                                      "send invoice"
                                    )}
                                  </button>
                                )}
                              </div>
                            </Accordion.Body>
                          </AccordionItem>
                        </Accordion>
                      </>
                    )}

                    <button
                      disabled={isCustomPriceLoading}
                      onClick={() => updateCustomAmount()}
                    >
                      {isCustomPriceLoading ? (
                        <SmallLoader color={"#fff"} />
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="col-6 d-flex justify-content-center align-items-center">
                  <h4 className="text-center">No Invoice Found!</h4>
                </div>
              )}
            </>
          )}
      </div>
    </div>
  );
};

export default FormStudentInfo;
