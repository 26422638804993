import React from 'react'
import LayoutWrapper from '../components/layout/LayoutWrapper'
import ContactUsEmailComponent from '../components/formTables/ContactUsEmailComponent'
import EmailHeader from '../components/Emailchats/EmailHeader'
import { useLocation, useParams } from 'react-router-dom'
import ContactUsEmailDetail from '../components/formTables/ContactUsEmailDetail'

const ContactUsEmail = () => {
    const { pathname } = useLocation()
    const { id ,type  , email} = useParams()
    return (
        <LayoutWrapper>
            <EmailHeader />
            {pathname === `/forms/contactus-email/${id}` && <ContactUsEmailComponent />}
            {pathname === `/forms/contactus-email-details/${id}/${type}/${email}` && <ContactUsEmailDetail />}
        </LayoutWrapper>
    )
}

export default ContactUsEmail