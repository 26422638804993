import { useEffect, useMemo, useState } from "react";
import axios from "../../axios";
import ct from "countries-and-timezones";
import cc from "currency-codes";
import { AnimatePresence, motion } from "framer-motion";
import { ColorRing } from "react-loader-spinner";
import { hasFlag } from "country-flag-icons";
import { MdOutlineContentCopy } from "react-icons/md";
import getUnicodeFlagIcon from "country-flag-icons/unicode";




var data = require('currency-codes/data');
let dbnc_timeout = null;

function isSpaces(str) {
  if (typeof str !== "string") return true;
  return str.split(" ").join() === "";
}

export default function InstallmentPayment() {

  const formatter = useMemo(() => new Intl.NumberFormat("en-IN", { minimumFractionDigits: 2, maximumFractionDigits: 2 }), [])

  const [allCourses, setAllCourses] = useState([]);
  const allCountries = cc.countries().map(item => `${item}__XX`);
  const [inpCountry, setInpCountry] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionsLoading, setSuggestionsLoading] = useState(false);
  const [chosenCountry, setChosenCountry] = useState(null);
  const [chosenCurrencyCode, setChosenCurrencyCode] = useState(null);
  const [selectedCode, setSelectedCode] = useState(null);
  const [courseName, setCourseName] = useState("");

  async function handleCopy(price) {
    await navigator.clipboard.writeText(price);
    // setIsCopied(true);
    // setTimeout(()=>{ setIsCopied(false); }, 20000)
  }

  // Calculation:

  const [installmentType, setInstallmentType] = useState(2);
  const [calculating, setCalculating] = useState(false);
  const [result, setResult] = useState(null);

  async function fetchAllCourses() {
    try {
      const url = "/dashboard-data/get-all-courses";
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
        }
      }
      const response = await axios.get(url, config);
      if (response.status === 200) {
        setAllCourses(response.data.allCourses);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function handleCalculation(controller) {
    try {
      // set the calculation loader
      // make some API calls to get the program fee, and exchange rates
      setCalculating(true);
      const url = "/dashboard-data/get-installments-calc";
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`
        }
      };

      const data = {
        installmentType,
        courseName,
        currencyCode: selectedCode,
        country: chosenCountry.split("__")[0],
      }
      const response = await axios.post(url, data, config);
      if (response.status === 200) {
        console.log(response);
        setCalculating(false);
        setResult(response.data?.installments)
      }
    } catch (error) {
      console.log(error);
      setCalculating(false);
    }
  }

  function reduceToDust() {
    setResult(null);
    setSuggestionsLoading(true);
    setInpCountry("");
    setSuggestions([]);
    setChosenCountry(null);
    setSelectedCode(null)
    setChosenCurrencyCode([]);
    setCourseName("");
    setInstallmentType(2);
  }

  function handleCountryInput(e) {
    setResult(null);
    setSuggestionsLoading(true);
    setInpCountry(e.target.value);
    setSuggestions([]);
    setChosenCountry(null);
    setSelectedCode(null)
    setChosenCurrencyCode([]);
    if (dbnc_timeout) clearTimeout(dbnc_timeout);
    dbnc_timeout = setTimeout(() => {
      if (e.target.value === "" || isSpaces(e.target.value)) return;
      const filtered_p = allCountries.filter(item => item.toLowerCase().startsWith(e.target.value.toLowerCase()));
      const filtered_s = allCountries.filter(item => item.toLowerCase().includes(e.target.value.toLowerCase()));
      const final = Array.from(new Set([...filtered_p, ...filtered_s]));
      setSuggestions(final);
      setSuggestionsLoading(false);
    }, 500);
  }

  function handleChosenCountry(item) {
    setSuggestions([]);
    setSuggestionsLoading(false);
    setInpCountry(item.split("__")[0]);
    setChosenCountry(item);
  }

  useEffect(() => {
    fetchAllCourses();
  }, [allCourses.length]);

  function getSyncedCountryName(name) {
    const countries = cc.countries();
    console.log(countries)
    return countries.filter(item => item.toLowerCase()?.includes(name))[0];
  }

  console.log("chosen country", chosenCountry)

  useEffect(() => {
    if (chosenCountry) {
      console.log("sync cont name", getSyncedCountryName(chosenCountry.split("__")[0].toLowerCase()))
      const temp = cc.country(getSyncedCountryName(chosenCountry.split("__")[0].toLowerCase()));
      console.log("temp", temp);
      setChosenCurrencyCode(temp.map(item => `${item.code}__${item.currency}`));
    }
  }, [chosenCountry])

  console.log(chosenCountry)
  console.log(chosenCurrencyCode)

  return (
    <div className="installment-content-parent">
      <form action="">

        <div className="installment-form">
          <div className="installment-form-item">
            <p>Select a Course</p>
            <div className="select-wr">
              <select onChange={(e) => { setCourseName(e.target.value); }} value={courseName} disabled={calculating || result} name="course" id="">
                <option value={""}>Choose a Course</option>
                {
                  allCourses?.length > 0 && allCourses.map(item => (
                    <option value={item}>{item}</option>
                  ))
                }
              </select>
            </div>
          </div>

          <div className="installment-form-item">
            <p>Installment Type</p>
            <div className="installment-type-tray">
              <div className="radio-wr">
                <input value={installmentType} onChange={() => { setInstallmentType(2) }} disabled={calculating || result} name="installmentType" id="installment-type2-radio" type="radio" />
                <label htmlFor="installment-type2-radio">2 Installments</label>
              </div>
              <div className="radio-wr">
                <input value={installmentType} onChange={() => { setInstallmentType(3) }} disabled={calculating || result} name="installmentType" id="installment-type3-radio" type="radio" />
                <label htmlFor="installment-type3-radio">3 Installments</label>
              </div>
            </div>
          </div>

          <div className="installment-form-item">
            <p>Currency</p>
            <div className="suggestive-inp-wr">
              <input disabled={calculating || result} placeholder="Type in a country" onChange={handleCountryInput} value={inpCountry} type="text" style={{ width: "100%" }} />
              <AnimatePresence>
                {
                  inpCountry !== "" && (
                    <motion.div key={"suggestions-calc"} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="suggestion-holder">
                      {suggestionsLoading && <ColorRing width={50} height={50} colors={['#3292F1', '#3292F1', '#3292F1', '#3292F1', '#3292F1']} />}
                      {suggestions && !suggestionsLoading && (
                        suggestions.map(item => (
                          <div onClick={() => { handleChosenCountry(item) }} className="suggestion-item">{hasFlag(item.split("__")[1]) && getUnicodeFlagIcon(item.split("__")[1])} {item.split("__")[0]}</div>
                        ))
                      )}
                    </motion.div>
                  )
                }
              </AnimatePresence>
            </div>
          </div>

          {chosenCurrencyCode?.length > 0 && (
            <div className="installment-form-item">
              <p>Choose Currency Code<br /> <span style={{ fontSize: "14px", color: "gray" }}>(There may be multiple, please choose the required one)</span></p>
              {
                chosenCurrencyCode.map(item => (
                  <div onClick={() => { setSelectedCode(item.split("__")[0]) }} className={`${selectedCode === item.split("__")[0] && "curr-item-smcard-active"} curr-item-smcard`}>
                    <p><strong>{item.split("__")[0]}</strong></p>
                    <p>{item.split("__")[1]}</p>
                  </div>
                ))
              }
            </div>
          )}

          {(selectedCode && courseName && installmentType) && <div className="installment-form-item">
            <button disabled={calculating || result} onClick={handleCalculation} className="calc-main-btn">{calculating ? <ColorRing width={30} height={30} colors={['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff',]} /> : "Calculate"}</button>
          </div>}

          {result && <div className="installment-form-item">
            <button disabled={calculating} onClick={reduceToDust} className="calc-sec-btn">Clear</button>
          </div>}

        </div>


      </form>

      <div className="installment-result">
        {result ? <>
          {/* GET:  selectedCode, programName, Installment-type
              CALL API: Get progream Fee, Discounts(if any), exchange rates
          */}
          <h4 className="installment-result-head">Installment Payments</h4>
          <div className="installment-dt-row"><p>Currency Code</p><p> {selectedCode} </p></div>
          {
            installmentType === 2 && (
              <>
                <div className="installment-dt-row"><p>Installment 1 </p><p> {formatter.format(Math.round(result.installment_1.net_amount))} <MdOutlineContentCopy onClick={() => { handleCopy(Math.round(result.installment_1.net_amount)) }} style={{ color: "#119dde", cursor: "pointer" }} /></p></div>
                <div className="installment-dt-row"><p>Installment 2 </p><p> {formatter.format(Math.round(result.installment_2.net_amount))} <MdOutlineContentCopy onClick={() => { handleCopy(Math.round(result.installment_2.net_amount)) }} style={{ color: "#119dde", cursor: "pointer" }} /></p></div>
              </>
            )
          }
          {
            installmentType === 3 && (
              <>
                <div className="installment-dt-row"><p>Installment 1 </p><p> {formatter.format(Math.round(result.installment_1.net_amount))} <MdOutlineContentCopy onClick={() => { handleCopy(Math.round(result.installment_1.net_amount)) }} style={{ color: "#119dde", cursor: "pointer" }} /></p></div>
                <div className="installment-dt-row"><p>Installment 2 </p><p> {formatter.format(Math.round(result.installment_2.net_amount))} <MdOutlineContentCopy onClick={() => { handleCopy(Math.round(result.installment_2.net_amount)) }} style={{ color: "#119dde", cursor: "pointer" }} /></p></div>
                <div className="installment-dt-row"><p>Installment 3 </p><p> {formatter.format(Math.round(result.installment_3.net_amount))} <MdOutlineContentCopy onClick={() => { handleCopy(Math.round(result.installment_3.net_amount)) }} style={{ color: "#119dde", cursor: "pointer" }} /></p></div>
              </>
            )
          }

          <div className="grand-btm">
            <div className="installment-dt-row grand-total" style={{ marginBottom: "0px", background: "transparent" }} ><p>Grand  Total </p><p>{formatter.format(Math.round(result.grand_total + result.total_discount))} <MdOutlineContentCopy onClick={() => { handleCopy(Math.round(result.grand_total + result.total_discount)) }} style={{ color: "#119dde", cursor: "pointer" }} /></p></div>
            <div className="installment-dt-row grand-total" style={{ marginBottom: "0px", background: "transparent" }} ><p>Total Discount </p><p>{formatter.format(Math.round(result.total_discount))} <MdOutlineContentCopy onClick={() => { handleCopy(Math.round(result.total_discount)) }} style={{ color: "#119dde", cursor: "pointer" }} /></p></div>
            <div className="installment-dt-row grand-total">
              <p>Net Total </p>
              <p>{formatter.format(Math.round(result.grand_total))} <MdOutlineContentCopy onClick={() => { handleCopy(Math.round(result.grand_total)) }} style={{ color: "#119dde", cursor: "pointer" }} /></p>
            </div>
          </div>
        </> : <div style={{ display: "flex", height: "100%", color: "gray", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
          <p>Please fill the form</p>
        </div>}
      </div>
    </div>
  )
}