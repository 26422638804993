import React from "react";
import { Modal } from "react-bootstrap";

const ContactUsModal = ({ modalShow, setModalShow, message , name }) => {
  return (
    <Modal
      centered
      className="bootstrap-modal-custom comment-modal hierarchy_modal_container"
      show={modalShow}
      onHide={() => setModalShow(false)}
    >
      <Modal.Body>
        <h2>{name}'s Message </h2>
        <p>{message}</p>
      </Modal.Body>
    </Modal>
  );
};

export default ContactUsModal;
