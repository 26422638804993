import { useEffect, useState } from "react";
import axios from "../../axios";
import { MdOutlineContentCopy } from "react-icons/md";
import { ColorRing } from "react-loader-spinner";
import { AnimatePresence, motion } from "framer-motion";

export default function OneTimePayment() {

  const [allCourses, setAllCourses] = useState([]);
  const [courseName, setCourseName] = useState("");
  const [payments, setPayments] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);


    useEffect(() => {
      fetchAllCourses();
    }, [allCourses.length]);


  async function handleCopy(price) {
    await navigator.clipboard.writeText(price);
    setIsCopied(true);
    setTimeout(()=>{ setIsCopied(false); }, 20000)
  }

  async function fetchAllCourses() {
    try {
      
      const url = "/dashboard-data/get-all-courses";
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
        }
      }
      const response = await axios.get(url, config);
      if (response.status === 200) {
        
        setAllCourses(response.data.allCourses);
      }
    } catch (error) {
        console.log(error);
    }
  }

  async function fetchCourseOneTimePayment() {
    try {
      // 
      if(!courseName) return;
      setLoading(true);
      const url = "/dashboard-data/get-one-time-paycalc";
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
        }
      }
      const response = await axios.post(url, {courseName}, config);
      if (response.status === 200) {
        setLoading(false);
        setPayments(response.data.payments);
      }
    } catch (error) {
      setLoading(false);
      console.log(error)
    }
  }

  useEffect(()=>{
    fetchCourseOneTimePayment();
  },[courseName])

  return (
    <div className="installment-content-parent">
        <div className="installment-form">
          <div className="installment-form-item">
            <p>Select a Course</p>
            <div className="select-wr">
              <select onChange={(e) => { setCourseName(e.target.value); }} value={courseName} name="course" id="">
                <option value={""}>Choose a Course</option>
                {
                  allCourses?.length > 0 && allCourses.map(item => (
                    <option value={item}>{item}</option>
                  ))
                }
              </select>
              <AnimatePresence>
                {isCopied && <motion.div key={"price-copy-alert"} animate={{opacity:1}} exit={{opacity:0}} initial={{opacity:0}} className="price-copy-alert" >Price copied to clipboard!</motion.div>}
              </AnimatePresence>
              <div style={{display:"flex", flexDirection:"column", padding:"20px 10px", border:"1px solid gray", marginTop:"14px"}}>
                { (payments && !loading) ?
                  payments.map(item=>(
                    <p className="onetimepay-row">
                      <span>{item.country}</span>
                      <span>{item.symbol} {item.price} <MdOutlineContentCopy onClick={()=>{handleCopy(item.price)}} style={{color:"#119dde"}} /></span>
                    </p>
                  ))
                 : loading ? <ColorRing width={40} height={40} colors={['#3292F1', '#3292F1', '#3292F1', '#3292F1', '#3292F1']} /> : <p>Price details will come here</p>}
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}