import moment from "moment";
import React, { useEffect, useState } from "react";
import { images, notifySuccess } from "../../actions/customFn";
import { GoArrowLeft } from "react-icons/go";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../axios"
import ReactQuill from "react-quill";
import SmallLoader from "../../utils/SmallLoader";

const ContactUsEmailDetail = () => {
  const navigate = useNavigate();
  const [emailList, setEmailList] = useState([])
  const { id, type , email  } = useParams()

  const [body ,  setBody] = useState("")

  const [isLoading , setIsLoading] = useState(false)

  const getEmailDetails = () => {
    const url = `/email/contact-us/get-selected-email`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
      params: {
        folderId: id, messageId: type
      }
    };

    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;

        setEmailList(data || []);
      })
      .catch((err) => {
        console.log(err);
      });
  }


  useEffect(() => {
    getEmailDetails()
  }, [type, id])


    const sendEmail = () => {
      setIsLoading(true);
      const url = `/email/contact-us/replyto-email`;
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
        },
      };
      const data = {
        fromAddress: "hello@oeson.in",
        toAddress: email,
        content: body,
        messageId:type
      };
  
      axios
        .post(url, data, config)
        .then((res) => {
          const { message } = res.data;
          notifySuccess(message);
          setIsLoading(false);
          setBody("")
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    };
  

  return (
    <>
      <div className="email_chats_content">
        <div className="email_chats_content_profile_container contact_email_chats_content_profile_container contact_email_chats_content_profile_container scrollbar-template">
          <p dangerouslySetInnerHTML={{ __html: emailList.content }}></p>
          <div className="email_chats_content_underline"></div>
        </div>
        <div className="contact_email_chats_content">
            <ReactQuill
              theme="snow"
              value={body}
              onChange={(e) => setBody(e)}
            />
            <div className="contact_email_chats_content_btn">
               <button onClick={sendEmail} disabled={isLoading} >{isLoading ? <SmallLoader color={"#fff"}/> : "Reply"}</button>
            </div>
        </div>
      </div>
    </>
  );
};

export default ContactUsEmailDetail;
