import { Suspense, useEffect } from "react";
import { Routes, useLocation, useNavigate } from "react-router-dom";
import RoutesArr from "./routes";
import { GetRoutes } from "./actions/customFn";
import "bootstrap/dist/css/bootstrap.min.css";
import "./asset/css/style.css";
import "./asset/css/responsive.css";
import "./asset/css/newStyle.css";
import "./asset/css/newResponsive.css";

import { useDispatch, useSelector } from "react-redux";
import { setLogin, setLogout } from "./slice/authSlice";
import { asyncLogOut, asyncProfileData } from "./actions/loginAction";
import { jwtDecode } from "jwt-decode";


function App() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isLoggedIn = useSelector((state) => state.authData.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("oeson_auth")) {
      dispatch(setLogin());
    } else {
      dispatch(setLogout());
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(asyncProfileData());
    }
  }, [isLoggedIn]);

  const checkTokenExp = () => {
    const token = localStorage.getItem("oeson_auth");
    if (token) {
      if (jwtDecode(token).exp < Date.now() / 1000) {
        dispatch(asyncLogOut(navigate))
      }
    }
  };

  useEffect(() => {
    checkTokenExp();
  }, [pathname]);
  
  return (
    <Suspense fallback={"loading....."}>
      <Routes>{GetRoutes(RoutesArr)}</Routes>
    </Suspense>
  );
}

export default App;
