import React, { useEffect, useState } from "react";
import { IoChevronDown } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncCountryData,
  asyncSendOfferLetter,
} from "../../actions/rootAction";
import SmallLoader from "../../utils/SmallLoader";
import { handleScrollToError } from "../../actions/customFn";

const SendOfferLetterForm = ({ formData }) => {
  // const [image, setImage] = useState(null);
  const countryData = useSelector((state) => state.rootData.countryData);
  //
  const [formFields, setFormFields] = useState({
    name: "",
    email: "",
    address: "",
    program: "",
    joinDate: "",
    phone_no: "",
    phone_code: "",
    country: "",
  });

  const [gender, setGender] = useState("");

  const [isCustomize, setIsCustomize] = useState(false);
  const [file, setFile] = useState("");

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const [formError, setFormError] = useState({});

  const offerLetterErrors = {};

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const offerLetterValidation = () => {
    if (formFields.name === "") {
      offerLetterErrors.name = "Name is required";
    }
    if (formFields.email === "") {
      offerLetterErrors.email = "Email is required";
    }
    if (formFields.address === "") {
      offerLetterErrors.address = "Address is required";
    }
    if (formFields.program === "") {
      offerLetterErrors.program = "program is required";
    }
    if (formFields.joinDate === "") {
      offerLetterErrors.joinDate = "joinDate is required";
    }
    if (formFields.phone_no === "") {
      offerLetterErrors.phone_no = "Phone no is required";
    }
    if (formFields.phone_code === "") {
      offerLetterErrors.phone_code = "Phone code is required";
    }
    if (formFields.country === "") {
      offerLetterErrors.country = "country is required";
    }
    if(isCustomize && file ===""){
      offerLetterErrors.file = "PDF is required"
    }
    setFormError(offerLetterErrors);
  };

  useEffect(() => {
    if (formData) {
      const dateFormat = formData?.joining_date?.split("/").reverse().join("-");
      setFormFields({
        name: formData.username,
        email: formData.email,
        address: formData.address,
        program: formData.program_name,
        joinDate: dateFormat,
        phone_no: Number(formData?.contact_no?.split(" ")[1]) || "",
        phone_code: formData?.contact_no?.split(" ")[0] || "",
        country: formData.country,
      });
      setGender(formData.gender);
    }
  }, [formData]);

  const onDataChange = (e) => {
    const { value, name } = e.target;
    setFormFields({ ...formFields, [name]: value });
  };

  useEffect(() => {
    dispatch(asyncCountryData());
  }, []);

  const sendOfferLetter = () => {
    offerLetterValidation();
    if (Object.keys(offerLetterErrors).length === 0) {
      // e.preventDefault();
      setIsLoading(true);
  let joining_date =  new Date(formFields.joinDate).toLocaleDateString(
          "en-GB",
          {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          }
        )
      const formData  = new FormData()
      formData.append("name" , formFields.name )
      formData.append("email" , formFields.email)
      formData.append("address" , formFields.address)
      formData.append("joining_date" , joining_date)
      formData.append("program_name" , formFields.program)
      formData.append("country" , formFields.country)
      formData.append("contact" , formFields.phone_code + " " + formFields.phone_no)
      formData.append("gender" , gender)
      if(file !==""){
      formData.append("offer_letter" , file)
      }


      dispatch(asyncSendOfferLetter(formData, setIsLoading));
    }
  };

  useEffect(() => {
    if (Object.keys(formError).length) {
      handleScrollToError("form_error_div");
    }
  }, [formError]);

  const phoneErrorMessage = formError.phone_code || formError.phone_no;

  // useEffect(() => {
  //   if (Object.keys(formError).length) {
  //     handleScrollToError("form_error_div");
  //   }
  // });


  useEffect(()=>{
  if(isCustomize === false){
    setFile("")
  }
  },[isCustomize])

  return (
    <>
      <div className="send_offer_letter_form_container scrollbar-template">
        <div className="send_offer_letter_form">
          <div className="send_offer_letter_form_div">
            <div className="send_offer_letter_input">
              <label htmlFor="">Name</label>
              <input
                type="text"
                placeholder="Name"
                name="name"
                onChange={onDataChange}
                value={formFields.name}
              />
              {formError.name && (
                <div className="form_error_div">{formError.name}</div>
              )}
            </div>

            <div className="send_offer_letter_input">
              <label htmlFor="">Email ID</label>
              <input
                type="email"
                placeholder="Email Address"
                value={formFields.email}
                name="email"
                onChange={onDataChange}
              />
              {formError.email && (
                <div className="form_error_div">{formError.email}</div>
              )}
            </div>

            <div className="send_offer_letter_input">
              <label htmlFor="">Country</label>
              <div className="send_offer_letter_select">
                <select
                  id=""
                  value={formFields.country}
                  name="country"
                  onChange={onDataChange}
                >
                  <option value="">select country</option>
                  {countryData.map((data, i) => {
                    return <option>{data.label}</option>;
                  })}
                </select>
                <IoChevronDown className="down_icon" />
              </div>

              {formError.country && (
                <div className="form_error_div">{formError.country}</div>
              )}
            </div>

            <div className="send_offer_letter_input">
              <label htmlFor="">Address</label>
              <input
                type="text"
                placeholder="Your Address"
                value={formFields.address}
                name="address"
                onChange={onDataChange}
              />

              {formError.address && (
                <div className="form_error_div">{formError.address}</div>
              )}
            </div>

            <div className="send_offer_letter_input">
              <label htmlFor="">Phone Number</label>
              <div className="send_offer_letter_phone_input">
                <div className="send_offer_letter_phone_input_select">
                  <select
                    name="phone_code"
                    onChange={onDataChange}
                    value={formFields.phone_code}
                  >
                    <option value="">Select Phone code</option>
                    {countryData.map((data, i) => {
                      return (
                        <option value={data.value}>
                          {data.label}({data.value})
                        </option>
                      );
                    })}
                  </select>
                  <IoChevronDown className="down_icon" />
                </div>
                <input
                  type="number"
                  name="phone_no"
                  value={formFields.phone_no}
                  onChange={onDataChange}
                  placeholder="Enter Phone Number"
                />
              </div>
              {/* {formError.phone_code ||
                (formError.phone_no && (
                  <div className="form_error_div">
                    {formError.phone_code || formError.phone_no}
                  </div>
                ))} */}
              {phoneErrorMessage && (
                <div className="form_error_div">{phoneErrorMessage}</div>
              )}
            </div>

            <div className="send_offer_letter_input">
              <label htmlFor="">Gender</label>
              <div className="send_offer_letter_gender_button">
                <button
                  onClick={() => setGender("Female")}
                  className={`${gender === "Female" ? "active" : ""} `}
                >
                  Female
                </button>
                <button
                  onClick={() => setGender("Male")}
                  className={`${gender === "Male" ? "active" : ""} `}
                >
                  Male
                </button>
              </div>

              {formError.gender && (
                <div className="form_error_div">{formError.gender}</div>
              )}
            </div>

            <div className="send_offer_letter_input">
              <label htmlFor="">Joining Date</label>
              <input
                type="date"
                placeholder=""
                value={formFields.joinDate}
                onChange={onDataChange}
                name="joinDate"
              />

              {formError.joinDate && (
                <div className="form_error_div">{formError.joinDate}</div>
              )}
            </div>

            <div className="send_offer_letter_input">
              <label htmlFor="">Select Program</label>
              <div className="send_offer_letter_select">
                <select
                  id=""
                  name="program"
                  onChange={onDataChange}
                  value={formFields.program}
                >
                  <option value={""}>Select Program</option>
                  <option value={"Data Science"}>Data Science</option>
                  <option value={"Digital Marketing"}>Digital Marketing</option>
                  <option value={"Data Analytics"}>Data Analytics</option>
                  <option value={"Business Analytics"}>
                    Business Analytics
                  </option>
                  <option value={"Cyber Security"}>Cyber Security</option>
                  <option value={"Data Engineering"}>Data Engineering</option>
                </select>
                <IoChevronDown className="down_icon" />
              </div>
              {/* <input type="text" placeholder="First Name" /> */}
              {formError.program && (
                <div className="form_error_div">{formError.program}</div>
              )}
            </div>

            {isCustomize && (
              <div className="send_offer_letter_input w-100">
                <label htmlFor="">Offer Letter</label>
                <input type="file" onChange={handleFileChange} accept="application/pdf" />
                {formError.file  && (
                  <div className="form_error_div">{formError.file}</div>
                )}
              </div>
            )}
          </div>

          <div className="send_offer_letter_form_buttons">
            <button onClick={()=>setIsCustomize(p=>!p)}>Customize</button>
            <button onClick={sendOfferLetter}>
              {isLoading ? <SmallLoader color={"#fff"} /> : "Send Offer Letter"}
            </button>
          </div>
        </div>
      </div>
      {/* national */}
    </>
  );
};

export default SendOfferLetterForm;
