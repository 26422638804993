import { useEffect, useState } from "react";
import { GoAlertFill } from "react-icons/go";
import axios from "../../axios";
import { ColorRing } from "react-loader-spinner";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { prepareData } from "./analysisFunctions";


export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Oeson Dashboard Analysis',
    },
  },
};

export default function CourseAnalysis() {

	const [filterButtonOption, setFilterButtonOption] = useState("CURR");
	const [allCourses, setAllCourses] = useState([]);
	const [currentCourse, setCurrentCourse] = useState("all");
	const [graphData, setGraphData] = useState(null);
	const [loading, setLoading] = useState(false);

	async function fetchAllCourses() {
		try {
			const url = "/dashboard-data/get-all-courses";
			const config = {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
				}
			}
			const response = await axios.get(url, config);
			if (response.status === 200) {
				setAllCourses(response.data.allCourses);
			}
		} catch (error) {
			console.log(error);
		}
	}
	useEffect(() => {
		fetchAllCourses();
	}, [allCourses.length])

	async function fetchCourseData(controller) {
		try {
			setLoading(true);
			const url = '/dashboard-data/get-course-analytics';
			const config = {
				signal: controller.signal,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`
				}
			}
			const data = {
				filters: {
					year: filterButtonOption,
					courseName: currentCourse
				}
			}

			const response = await axios.post(url, data, config);
			if (response.status === 200) {
				const final = prepareData(response?.data?.courseData, filterButtonOption, null, null, false);
				setGraphData(final);
				setLoading(false);
			}
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	}
	useEffect(() => {
		const controller = new AbortController();
		fetchCourseData(controller);
		return () => {
			controller.abort();
		}
	}, [filterButtonOption, currentCourse])

	console.log("COURSE ANALYTICS RESPONSE", currentCourse);

	return (
		<>
			<div className="graph-inner-heading">
				<div className="graph-inner-heading-left">
					<p className="graph-title-main">Graph for {currentCourse} Course</p>
				</div>

				<div className="graph-inner-heading-right">
					{allCourses.length > 0 ?
						(
							<select onChange={(e) => { setCurrentCourse(e.target.value) }} className="dash-analyt-select-lead">
								<option value={"all"}>All Courses</option>
								{
									allCourses?.map(item => (
										<option value={item}>{item}</option>
									))
								}
							</select>
						) : (
							<button><ColorRing width={20} height={20} colors={['#528ff0', '#528ff0', '#528ff0', '#528ff0', '#528ff0']} /></button>
						)}
					<button onClick={() => { setFilterButtonOption("CURR") }} className={filterButtonOption === "CURR" && 'active'}>{new Date().getFullYear()}</button>
					<button onClick={() => { setFilterButtonOption("YOY") }} className={filterButtonOption === "YOY" && 'active'}>YoY</button>
				</div>
			</div>

			<div className="dashboard-graph-tabber-content">
				<div className="graph-inner">
					{
						(!graphData && !loading) && (
							<>
								<h5 className="no-data-found-heading"><GoAlertFill style={{ color: "#ffa665", fontSize: "22px", marginBottom: "5px" }} /> No Data Available</h5>
								<p className="no-data-found-content">Tip: You can try again by searching a different filter</p>
							</>
						)
					}
					{loading && <ColorRing width={80} height={80} colors={['#528ff0', '#528ff0', '#528ff0', '#528ff0', '#528ff0']} />}
					{(graphData && !loading) && <Bar options={options} data={graphData} />}
				</div>
				<div className="graph-button-tray"></div>
			</div>
		</>
	)
}