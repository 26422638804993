import { useEffect, useState } from "react"
import axios from "../../axios";
import { ColorRing } from "react-loader-spinner";

export default function DashboardLeaderBoard() {
  // /api/leads/get-lead-rankings
  const [rankings, setRankings] = useState([]);
  const [loading, setLoading] = useState(false);

  async function fetchRankings() {
    try {
      setLoading(true);
      const url = "/leads/get-lead-rankings";
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`
        }
      };
      const response = await axios.get(url, config);
      if (response.status === 200) {
        setLoading(false);
        setRankings(response.data?.rankings);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  useEffect(() => {
    fetchRankings();
  }, [])

  console.log(rankings.map(item => `${item.username} - ${item.leadCount}`))

  return (
    <div className="dashboard-leaderboard-wr">
      <h3 className="leaderboard-heading-blaze" >Top Performers this month</h3>
      <div className="main-ranking-table">
        {
          loading && (
            <div style={{display:"flex", padding:"25px 0px", alignItems:"center", justifyContent:"center", gap:"15px", flexDirection:"column"}}>
              <ColorRing width={80} height={80} colors={["#fff", "#fff", "#fff", "#fff", "#fff"]} />
              <p style={{color:"#fff", fontSize:"18px", margin:"0px"}}>Loading Leaderboard!</p>
            </div>
          )
        }
        {
          rankings.length > 0 && (
            <>
            
              <div className="rank-card rank-2-card">
                <div className="rank-2-card-star"></div>
                <figure className="rank-img-holder">
                  <img style={{objectFit:"cover"}} src={rankings[1]?.image || "/leaderboard-placeholder.png"} />
                </figure>
                  <p className="rank-badge rank-badge-2">Rank 2</p>
                <h4>{rankings[1]?.username}</h4>
              </div>

              <div className="rank-card rank-1-card">
                <figure className="rank-img-holder golden-leaf-wr">
                  <img style={{objectFit:"cover"}} src={rankings[0]?.image || "/leaderboard-placeholder.png"} />
                </figure>
                  <p className="rank-badge rank-badge-1">Rank 1</p>
                <h4>{rankings[0]?.username}</h4>
              </div>

              <div className="rank-card rank-3-card">
                <div className="rank-3-card-star"></div>
                <figure className="rank-img-holder">
                  <img style={{objectFit:"cover"}} src={rankings[2]?.image || "/leaderboard-placeholder.png"} />
                </figure>
                  <p className="rank-badge rank-badge-3">Rank 3</p>
                <h4>{rankings[2]?.username}</h4>
              </div>
            </>
          )
        }

      </div>
      <div className="leaderboard-btm-strip"></div>
    </div>
  )
}