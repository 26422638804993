import { useCallback, useEffect, useMemo, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { GoAlertFill } from "react-icons/go";
import axios from "../../axios"
import { ColorRing } from "react-loader-spinner";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { prepareData } from "./analysisFunctions";
import { useSelector } from "react-redux";


export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Oeson Dashboard Analysis',
    },
  },
  scales: {
    xAxes: [
      {
        stacked: true,
        // Optional grid line customization
        gridLines: {
          display: true,
        },
      },
    ],
    yAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
        // Optional grid line customization
        gridLines: {
          display: true,
        },
      },
    ],
  },
};


export default function LeadsAnalysis() {

  const userId = useSelector((state) => state.profileData.userData);
  console.log(userId)
	const [salesPersons, setSalesPersons] = useState([]);
	const [filterButtonOption, setFilterButtonOption] = useState("CURR");
	const [currentSalesPerson, setCurrentSalesPerson] = useState(userId ? userId?.email : null);
	const [leadsData, setLeadsData] = useState(null);
	const [graphData, setGraphData] = useState(null);
	const [loading, setLoading] = useState(false);
  const [showLeaderBoardGraph, setShowLeaderBoardGraph] = useState(false);

	async function fetchLeadsData(controller) {
		try {
			setLoading(true);
			const url = "/dashboard-data/get-leads-data";
			const config = {
				signal: controller.signal,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`
				}
			};
			const response = await axios.post(url, {
				filters: {
					salesPerson: currentSalesPerson,
					year: filterButtonOption,
				}
			}, config);
			if (response.status === 200) {
				setLeadsData(response.data?.leadData);
				const data = prepareData(response.data?.leadData, filterButtonOption, currentSalesPerson, null, true);
				setGraphData(data);
				setLoading(false);
			}
		} catch (error) {
			setLoading(false);
			console.log(error)
		}
	}

  async function fetchLeaderBoardGraphData(controller) {
		try {
			setLoading(true);
			const url = "/dashboard-data/get-leaderboard-data";
			const config = {
				signal: controller.signal,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`
				}
			};
			const response = await axios.post(url, {
				filters: {
					salesPerson: currentSalesPerson,
					year: filterButtonOption,
				}
			}, config);
			if (response.status === 200) {
				setLeadsData(response.data?.rankings);
				const data = prepareData(response.data?.rankings, "LEADERBOARD");
				setGraphData(data);
				setLoading(false);
			}
		} catch (error) {
			setLoading(false);
			console.log(error)
		}
	}


	useEffect(() => {
		const controller = new AbortController();
    if(showLeaderBoardGraph) {
      fetchLeaderBoardGraphData(controller);
    } else {
      fetchLeadsData(controller);
    }
		return () => {
			controller.abort();
		}
	}, [filterButtonOption, currentSalesPerson, showLeaderBoardGraph])

	const getSeriesStyle = useCallback(
		(series) => ({
			color: series.index === 0 ? '#578FCA' : '#578FCA', // Custom colors for each series
			barThickness: 10, // Set bar thickness (in pixels)
		}),
		[]
	);

	console.log("LEAD DATA", graphData);
	return (
		<>
			<div className="graph-inner-heading">
				<div className="graph-inner-heading-left">
					<p className="graph-title-main">Leads analysis for {filterButtonOption === "CURR" ? "current year" : "YoY"}</p>
				</div>

				<div className="graph-inner-heading-right">
					<button onClick={()=>{setShowLeaderBoardGraph(false)}} className={!showLeaderBoardGraph ? "active" : ""} style={{width:"auto"}}>My Leads</button>
					<button onClick={()=>{setShowLeaderBoardGraph(true)}} className={showLeaderBoardGraph ? "active" : ""} style={{width:"auto"}}>Leaderboard</button>
				</div>
			</div>

			<div className="dashboard-graph-tabber-content">
				<div className="graph-inner">
					{
						(!leadsData && !loading) && (
							<>
								<h5 className="no-data-found-heading"><GoAlertFill style={{ color: "#ffa665", fontSize: "22px", marginBottom: "5px" }} /> No Data Available</h5>
								<p className="no-data-found-content">Tip: You can try again by searching a different filter</p>
							</>
						)
					}
					{loading && <ColorRing width={80} height={80} colors={['#528ff0', '#528ff0', '#528ff0', '#528ff0', '#528ff0']} />}
					{(graphData && !loading) && <Bar options={options} data={graphData} />}
				</div>
				<div className="graph-button-tray"></div>
			</div>
		</>
	)
}