export function prepareData(data, filter, person, amountType, isStacked) {
  if (!data) return null;

  if(filter === "LEADERBOARD") {
    const preparedData = {
      labels: [...data?.map(item => item.username)],
      datasets: [
        { label: "Total Leads", data: Array.from({ length: data.length }, (item, index) => { return 0; }), backgroundColor: '#4d7fee' },
      ]
    };

    data.map(item => {
      preparedData.datasets[0].data[preparedData?.labels.indexOf(item.username)] = item?.leadCount;
    })
    return preparedData;

  } else if (filter === "CURR") {
    // monthly return krna hoga 
    const preparedData = {
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      datasets: isStacked ? [
        { label: (amountType && amountType === "amount") ? "₹" : "Claimed", data: Array.from({ length: 12 }, (item, index) => { return 0; }), backgroundColor: '#4d7fee' },
        { label: (amountType && amountType === "amount") ? "₹" : "Conflicted", data: Array.from({ length: 12 }, (item, index) => { return 0; }), backgroundColor: '#FCB603' },
      ] : [
        { label: (amountType && amountType === "amount") ? "₹" : "Total", data: Array.from({ length: 12 }, (item, index) => { return 0; }), backgroundColor: '#4d7fee' },
      ]
    };
    data?.map(mainItem => {
      // get months
      const dt = new Date(mainItem.createdAt);
      const month = dt.getMonth();
      const amount = amountType && amountType === "amount" ? mainItem.amount_paid : null;
      switch (month) {
        case 0:
          if (isStacked) {
            if(mainItem?.disputed) {
              preparedData.datasets[1].data = preparedData.datasets[1]?.data.map((item, index) => {
                return index === 0 ? (amountType && amountType === "amount") ? item + amount : item + 1 : item;
              })
            } else {
              preparedData.datasets[0].data = preparedData.datasets[0]?.data.map((item, index) => {
                return index === 0 ? (amountType && amountType === "amount") ? item + amount : item + 1 : item;
              })
            }
            
          } else {
            preparedData.datasets[0].data = preparedData.datasets[0]?.data.map((item, index) => {
              return index === 0 ? (amountType && amountType === "amount") ? item + amount : item + 1 : item
            })
          }
          break;
        case 1:
          if (isStacked) {
            if(mainItem?.disputed) {
              preparedData.datasets[1].data = preparedData.datasets[1]?.data.map((item, index) => {
                return index === 1 ? (amountType && amountType === "amount") ? item + amount : item + 1 : item;
              })
            } else {
              preparedData.datasets[0].data = preparedData.datasets[0]?.data.map((item, index) => {
                return index === 1 ? (amountType && amountType === "amount") ? item + amount : item + 1 : item;
              })
            }
          } else {
            preparedData.datasets[0].data = preparedData.datasets[0]?.data.map((item, index) => {
              return index === 1 ? (amountType && amountType === "amount") ? item + amount : item + 1 : item
            })
          }
          break;
        case 2:
          if (isStacked) {
            if(mainItem?.disputed) {
              preparedData.datasets[1].data = preparedData.datasets[1]?.data.map((item, index) => {
                return index === 2 ? (amountType && amountType === "amount") ? item + amount : item + 1 : item;
              })
            } else {
              preparedData.datasets[0].data = preparedData.datasets[0]?.data.map((item, index) => {
                return index === 2 ? (amountType && amountType === "amount") ? item + amount : item + 1 : item;
              })
            }
            
          } else {
            preparedData.datasets[0].data = preparedData.datasets[0]?.data.map((item, index) => {
              return index === 2 ? (amountType && amountType === "amount") ? item + amount : item + 1 : item
            })
          }
          break;
        case 3:
          if (isStacked) {
            if(mainItem?.disputed) {
              preparedData.datasets[1].data = preparedData.datasets[1]?.data.map((item, index) => {
                return index === 3 ? (amountType && amountType === "amount") ? item + amount : item + 1 : item;
              })
            } else {
              preparedData.datasets[0].data = preparedData.datasets[0]?.data.map((item, index) => {
                return index === 3 ? (amountType && amountType === "amount") ? item + amount : item + 1 : item;
              })
            }
            
          } else {
            preparedData.datasets[0].data = preparedData.datasets[0]?.data.map((item, index) => {
              return index === 3 ? (amountType && amountType === "amount") ? item + amount : item + 1 : item
            })
          }
          break;
        case 4:
          if (isStacked) {
            if(mainItem?.disputed) {
              preparedData.datasets[1].data = preparedData.datasets[1]?.data.map((item, index) => {
                return index === 4 ? (amountType && amountType === "amount") ? item + amount : item + 1 : item;
              })
            } else {
              preparedData.datasets[0].data = preparedData.datasets[0]?.data.map((item, index) => {
                return index === 4 ? (amountType && amountType === "amount") ? item + amount : item + 1 : item;
              })
            }
            
          } else {
            preparedData.datasets[0].data = preparedData.datasets[0]?.data.map((item, index) => {
              return index === 4 ? (amountType && amountType === "amount") ? item + amount : item + 1 : item
            })
          }
          break;
        case 5:
          if (isStacked) {
            if(mainItem?.disputed) {
              preparedData.datasets[1].data = preparedData.datasets[1]?.data.map((item, index) => {
                return index === 5 ? (amountType && amountType === "amount") ? item + amount : item + 1 : item;
              })
            } else {
              preparedData.datasets[0].data = preparedData.datasets[0]?.data.map((item, index) => {
                return index === 5 ? (amountType && amountType === "amount") ? item + amount : item + 1 : item;
              })
            }
            
          } else {
            preparedData.datasets[0].data = preparedData.datasets[0]?.data.map((item, index) => {
              return index === 5 ? (amountType && amountType === "amount") ? item + amount : item + 1 : item
            })
          }
          break;
        case 6:
          if (isStacked) {
            if(mainItem?.disputed) {
              preparedData.datasets[1].data = preparedData.datasets[1]?.data.map((item, index) => {
                return index === 6 ? (amountType && amountType === "amount") ? item + amount : item + 1 : item;
              })
            } else {
              preparedData.datasets[0].data = preparedData.datasets[0]?.data.map((item, index) => {
                return index === 6 ? (amountType && amountType === "amount") ? item + amount : item + 1 : item;
              })
            }
            
          } else {
            preparedData.datasets[0].data = preparedData.datasets[0]?.data.map((item, index) => {
              return index === 6 ? (amountType && amountType === "amount") ? item + amount : item + 1 : item
            })
          }
          break;
        case 7:
          if (isStacked) {
            if(mainItem?.disputed) {
              preparedData.datasets[1].data = preparedData.datasets[1]?.data.map((item, index) => {
                return index === 7 ? (amountType && amountType === "amount") ? item + amount : item + 1 : item;
              })
            } else {
              preparedData.datasets[0].data = preparedData.datasets[0]?.data.map((item, index) => {
                return index === 7 ? (amountType && amountType === "amount") ? item + amount : item + 1 : item;
              })
            }
            
          } else {
            preparedData.datasets[0].data = preparedData.datasets[0]?.data.map((item, index) => {
              return index === 7 ? (amountType && amountType === "amount") ? item + amount : item + 1 : item
            })
          }
          break;
        case 8:
          if (isStacked) {
            if(mainItem?.disputed) {
              preparedData.datasets[1].data = preparedData.datasets[1]?.data.map((item, index) => {
                return index === 8 ? (amountType && amountType === "amount") ? item + amount : item + 1 : item;
              })
            } else {
              preparedData.datasets[0].data = preparedData.datasets[0]?.data.map((item, index) => {
                return index === 8 ? (amountType && amountType === "amount") ? item + amount : item + 1 : item;
              })
            }
            
          } else {
            preparedData.datasets[0].data = preparedData.datasets[0]?.data.map((item, index) => {
              return index === 8 ? (amountType && amountType === "amount") ? item + amount : item + 1 : item
            })
          }
          break;
        case 9:
          if (isStacked) {
            if(mainItem?.disputed) {
              preparedData.datasets[1].data = preparedData.datasets[1]?.data.map((item, index) => {
                return index === 9 ? (amountType && amountType === "amount") ? item + amount : item + 1 : item;
              })
            } else {
              preparedData.datasets[0].data = preparedData.datasets[0]?.data.map((item, index) => {
                return index === 9 ? (amountType && amountType === "amount") ? item + amount : item + 1 : item;
              })
            }
            
          } else {
            preparedData.datasets[0].data = preparedData.datasets[0]?.data.map((item, index) => {
              return index === 9 ? (amountType && amountType === "amount") ? item + amount : item + 1 : item
            })
          }
          break;
        case 10:
          if (isStacked) {
            if(mainItem?.disputed) {
              preparedData.datasets[1].data = preparedData.datasets[1]?.data.map((item, index) => {
                return index === 10 ? (amountType && amountType === "amount") ? item + amount : item + 1 : item;
              })
            } else {
              preparedData.datasets[0].data = preparedData.datasets[0]?.data.map((item, index) => {
                return index === 10 ? (amountType && amountType === "amount") ? item + amount : item + 1 : item;
              })
            }
            
          } else {
            preparedData.datasets[0].data = preparedData.datasets[0]?.data.map((item, index) => {
              return index === 10 ? (amountType && amountType === "amount") ? item + amount : item + 1 : item
            })
          }
          break;
        case 11:
          if (isStacked) {
            if(mainItem?.disputed) {
              preparedData.datasets[1].data = preparedData.datasets[1]?.data.map((item, index) => {
                return index === 11 ? (amountType && amountType === "amount") ? item + amount : item + 1 : item;
              })
            } else {
              preparedData.datasets[0].data = preparedData.datasets[0]?.data.map((item, index) => {
                return index === 11 ? (amountType && amountType === "amount") ? item + amount : item + 1 : item;
              })
            }
            
          } else {
            preparedData.datasets[0].data = preparedData.datasets[0]?.data.map((item, index) => {
              return index === 11 ? (amountType && amountType === "amount") ? item + amount : item + 1 : item
            })
          }
          break;
      }
    });
    return preparedData;

  } else if (filter === "YOY") {

    console.log("preparing for yoy", data);
    const cy = new Date().getFullYear();
    const preparedData = {
      labels: [`${cy - 2}`, `${cy - 1}`, `${cy}`],
      datasets: isStacked ? [
        { label: "Total Leads YoY", data: [0, 0, 0], backgroundColor: '#4d7fee' },
        { label: "Conflicted Leads YoY", data: [0, 0, 0], backgroundColor: '#FCB603' },
      ] : [
        { label: "Total Leads YoY", data: [0, 0, 0], backgroundColor: '#4d7fee' },
      ]
    };

    data.map(mainItem => {

      const dt = new Date(mainItem.createdAt);
      const yr = dt.getFullYear();
      const curr = new Date().getFullYear();
      const prev1yr = cy - 1;
      const prev2yr = cy - 2;
      const amount = amountType && amountType === "amount" ? mainItem.amount_paid : null;

      switch (yr) {
        case prev2yr:
          // current year
          if (isStacked) {
            preparedData.datasets[1].data = preparedData.datasets[1]?.data.map((item, index) => {
              return index === 0 ? (amountType && amountType === "amount") ? item + amount : mainItem?.disputed ? item + 1 : item + 1 : item
            })
          } else {
            preparedData.datasets[1].data = preparedData.datasets[1]?.data.map((item, index) => {
              return index === 0 ? (amountType && amountType === "amount") ? item + amount : item + 1 : item
            })
          }
          break;
        case prev1yr:
          if (isStacked) {
            preparedData.datasets[1].data = preparedData.datasets[1]?.data.map((item, index) => {
              return index === 1 ? (amountType && amountType === "amount") ? item + amount : mainItem?.disputed ? item + 1 : item + 1 : item
            })
          } else {
            preparedData.datasets[1].data = preparedData.datasets[1]?.data.map((item, index) => {
              return index === 1 ? (amountType && amountType === "amount") ? item + amount : item + 1 : item
            })
          }
          break;
        case curr:
          if (isStacked) {
            preparedData.datasets[1].data = preparedData.datasets[1]?.data.map((item, index) => {
              return index === 2 ? (amountType && amountType === "amount") ? item + amount : mainItem?.disputed ? item + 1 : item + 1 : item
            })
          } else {
            preparedData.datasets[1].data = preparedData.datasets[1]?.data.map((item, index) => {
              return index === 2 ? (amountType && amountType === "amount") ? item + amount : item + 1 : item
            })
          }
          break;
      }
    })
    return preparedData;
  } else return null;
}

