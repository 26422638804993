import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import ReactQuill from "react-quill";
import { useParams } from "react-router-dom";
import { notifySuccess } from "../../actions/customFn";
import axios from "../../axios";
import SmallLoader from "../../utils/SmallLoader";
import { ImEnlarge2 } from "react-icons/im";
import { RiCloseLargeLine } from "react-icons/ri";

const ContactEmailModal = ({ modalShow, setModalShow, setIsUpdate }) => {
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  let errors = {}
  const [formErrors, setFormErrors] = useState({})
    const [fullScreen, setFullScreen] = useState(false);

  const sendEmail = () => {
    validation()
    if (Object.keys(errors) === 0) {
      setIsLoading(true);
      const url = `/email/contact-us/send-email`;
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
        },
      };
      const data = {
        fromAddress: "hello@oeson.in",
        toAddress: id,
        subject,
        content: body,
      };

      axios
        .post(url, data, config)
        .then((res) => {
          const { message } = res.data;
          notifySuccess(message);
          setIsLoading(false);
          setModalShow(false)
          setIsUpdate(p => !p)
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  const validation = () => {
    if (subject.length === 0) {
      errors.subject = "Please provide subject!"
    }
    if (body.length === 0) {
      errors.body = "Please provide body!"
    }
    setFormErrors(errors)
  }

  return (

   <div className={`compose_email_box ${fullScreen && "active"}`}>
        <div className="compose_email_top">
          <h2>Compose Mail</h2>
          <span>
            <ImEnlarge2 size={20} onClick={() => setFullScreen(!fullScreen)} />
            <RiCloseLargeLine size={20} onClick={() => setModalShow(false)} />
          </span>
        </div>
        <div className="contactus_sendemail_modal">
          <div className="contactus_sendemail_modal_inp">
            <label>Subject</label>
            <input
              value={subject}
              type="text"
              required
              onChange={(e) => setSubject(e.target.value)}
            />
            {formErrors.subject && (
              <div className="form_error_div">{formErrors.subject}</div>
            )}
          </div>

          <div className="contactus_sendemail_modal_inp">
            <label>Email Body</label>
            <div className="compose_email_edit_text m-0">
              <ReactQuill
                theme="snow"
                value={body}
                onChange={(e) => setBody(e)}
              />

              {formErrors.body && (
                <div className="form_error_div">{formErrors.body}</div>
              )}
            </div>
          </div>
          <div className="contactus_sendemail_modal_submit">
            <button onClick={sendEmail} disabled={isLoading}>
              {isLoading ? <SmallLoader color={"#fff"} /> : "Send"}
            </button>
          </div>
        </div>
       
      </div>
  );
};

export default ContactEmailModal;
