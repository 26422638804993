import React, { useEffect, useState } from "react";
import { images } from "../../actions/customFn";
import moment from "moment";
import axios from "../../axios";
import { useNavigate, useParams } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import ContactEmailModal from "./ContactEmailModal";
import Loader from "../../utils/Loader";

const ContactUsEmailComponent = () => {
  const [emailList, setEmailList] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getEmailData = () => {
    setIsLoading(true);

    const url = `/email/contact-us/get-email-data`;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_auth")}`,
      },
      params: {
        email: id,
      },
    };

    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        setIsLoading(false);
        setEmailList(data || []);
      })
      .catch((err) => {
        setIsLoading(false);

        console.log(err);
      });
  };

  useEffect(() => {
    getEmailData();
  }, [id, isUpdate]);

  const extractEmail = (str) => {
    const match = str.replace(/&lt;|&gt;/g, "");
    return match ? match : null;
  };

  return (
    <div div className="contactus_email_chat_list_container">
      {isLoading ? (
        <Loader Height={"70vh"} />
      ) : (
        <div className="email_chat_list_container">
          {emailList?.length > 0 ? (
            emailList.map((data, i) => {
              return (
                <div
                  key={i}
                  style={{ backgroundColor: "#F5F5F5" }}
                  className="email_chat_list"
                  onClick={() =>
                    navigate(
                      `/forms/contactus-email-details/${data.folderId}/${
                        data.messageId
                      }/${
                        data.fromAddress === "hello@oeson.in"
                          ? extractEmail(data.toAddress)
                          : data.fromAddress
                      }`
                    )
                  }
                >
                  <img src={images["user-img.jpg"]} alt="" />
                  <div className="email_chat_list_content">
                    <span>
                      <h3>{data.fromAddress}</h3>

                      <h6>
                        {moment(Number(data.receivedTime)).format(
                          "DD MMM YYYY LT"
                        )}
                      </h6>
                    </span>
                    <p>{data.subject}</p>
                  </div>
                </div>
              );
            })
          ) : (
            <div
              className="d-flex justify-content-center align-items-center flex-column"
              style={{ height: "70vh" }}
            >
              <div className="d-flex justify-content-center">
                <img alt="" style={{width:"40%"}} src={images["email-not-found.png"]} />
              </div>
              <h4 className="text-black text-center ">
                No Conversation started yet!
              </h4>
            </div>
          )}
        </div>
      )}

      <button
        className="contactus_compose_email_btn"
        onClick={() => setModalShow(true)}
      >
        {" "}
        <span>Compose Email</span>
        <MdEdit />
      </button>

      {modalShow && (
        <ContactEmailModal
          setIsUpdate={setIsUpdate}
          modalShow={modalShow}
          setModalShow={setModalShow}
        />
      )}
    </div>
  );
};

export default ContactUsEmailComponent;
