import React, { useState } from "react";
import { images } from "../../actions/customFn";
import { MdEmail, MdError, MdErrorOutline } from "react-icons/md";
import { IoIosLock } from "react-icons/io";
import { NavLink, useNavigate } from "react-router-dom";
import { IoEye } from "react-icons/io5";
import { IoEyeOff } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { asyncLogin } from "../../actions/loginAction";
import SmallLoader from "../../utils/SmallLoader";
import { Formik } from "formik";
import { setLogin } from "../../slice/authSlice";
import axios from "../../axios"
import { ColorRing } from "react-loader-spinner";
import { VscEye, VscEyeClosed } from "react-icons/vsc";

import { AnimatePresence, motion } from "framer-motion";
// import SmallLoader from "../../utils/SmallLoader";

const LoginForm = () => {
  const [type, setType] = useState("password");

  const [formikErrorMessage, setFormikErrorMessage] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedInDevice, setIsLoggedInDevice] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const initialState = () => {
    setEmail("");
    setPassword("");
  };


  const LoginOtherDevice = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      email,
      password,
      loginType: "SalesPanel",
      isLoggedIn: true,
    };
    dispatch(asyncLogin(data, navigate, initialState, setIsLoading));
  };

  return (
    <div className="loginform_container">
      <div className="loginform_logo">
         <img src={images["new_side_oeson.png"]} style={{width:"200px", marginBottom:"50px"}} alt="" />
      </div>
      <div className="loginform_title">
        <h2><span>Sell Smart, Track Better</span> &mdash;Welcome Back!</h2>
      </div>
      <div className="loginform-formik-holder">
        <h4>Sign In</h4>
        <AnimatePresence>

          {formikErrorMessage && (
            <motion.p initial={{height:0, opacity:0}} animate={{height:"auto", opacity:1}} exit={{height:0, opacity:0}} key={"formik-error-msg-top"} className="formik-error-message"><MdErrorOutline style={{ fontSize: "24px" }} /> {formikErrorMessage}</motion.p>
          )}
        </AnimatePresence>
        <Formik
          initialValues={{ email: '', password: '' }}
          validate={values => {
            const errors = {};
            if (!values.email) {
              errors.email = 'Required';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Invalid email address';
            }

            if (!values.password) {
              errors.password = "Password is required"
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {

            const data = {
              email: values.email,
              password: values.password,
              loginType: "SalesPanel",
            };
            const url = "/auth/login";
            axios.post(url, data)
              .then((res) => {
                const { message, data, token } = res.data;
                dispatch(setLogin());
                localStorage.setItem("oeson_auth", token);
                localStorage.setItem("oeson_role", data.typeOfUser);
                localStorage.setItem("oeson_id", data._id);
                setSubmitting(false);
                navigate("/");
              })
              .catch((err) => {
                setSubmitting(false);
                const status = err?.response?.data?.status || "";
                if (status === 419 && setIsLoggedInDevice) {
                  setFormikErrorMessage("Another Login Session Detected");
                  setIsLoggedInDevice(true);
                }
                setFormikErrorMessage(err.message);
                console.log(err);
              });

          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="loginform-input-wr">
                <AnimatePresence>
                  {(errors.email  && touched.email) && <motion.span key="formik-email-valid-err" initial={{height:0, opacity:0}} animate={{height:"auto", opacity:1}} exit={{height:0, opacity:0}}>{errors.email && touched.email && errors.email}</motion.span>}
                </AnimatePresence>
                <input
                  className={errors.email && "inp-formik-err"}
                  type="email"
                  name="email"
                  placeholder="Enter your email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
              </div>
              <div className="loginform-input-wr">
                <div className="eye-password-formik" onClick={()=>{setShowPassword(prev => !prev)}}>{showPassword ? <VscEye /> : <VscEyeClosed />}</div>
                <AnimatePresence>
                  {(errors.password  && touched.password) && <motion.span key={"formik-password-valid-err"} initial={{height:0, opacity:0}} animate={{height:"auto", opacity:1}} exit={{height:0, opacity:0}}>{errors.password && touched.password && errors.password}</motion.span>}
                </AnimatePresence>
                <input
                  className={errors.password && "inp-formik-err"}
                  type={ showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Enter your password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                />
              </div>
              <button type="submit" disabled={isSubmitting}>
                {isSubmitting ? <ColorRing width={40} height={40} colors={['#fff', '#fff', '#fff', '#fff', '#fff']} /> : "Submit"}
              </button>
            </form>
          )}
        </Formik>
      </div>
      {isLoggedInDevice && (
        <div className="loginform_submit loginform_submit_other_device">
          <button disabled={isLoading} onClick={LoginOtherDevice}>
            {isLoading ? (
              <SmallLoader color={"#fff"} />
            ) : (
              "Logout from Other Device"
            )}{" "}
          </button>
        </div>
      )}
    </div>
  );
};

export default LoginForm;
